<template>
  <div>
    <!-- Activities History -->
    <v-expansion-panels
      class="mt-5"
      :value="$vuetify.breakpoint.smAndDown ? 1 : 0"
    >
      <v-expansion-panel class="px-2">
        <v-expansion-panel-header>
          <div class="subtitle-2">
            Activities History
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text
            v-if="activities.length > 0"
            class="activity-log-text pa-0"
          >
            <div
              v-for="activity in activities"
              :key="activity.id"
              class="mb-2"
            >
              <small class="font-weight-semibold primary--text mb-0">
                {{ activity.created_at }}
              </small>
              <p class="mb-0">
                {{ activity.description }}
              </p>
              <div v-if="activity.changed_fields">
                <div
                  v-for="(item, key, index) in activity.changed_fields"
                  :key="index"
                  class="ml-3"
                >
                  <small>
                    {{ key.split('_').join(' ') }}: {{ item[0] }} -> {{ item[1] }}
                  </small>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <p class="text-center mb-0">
              No activities yet!
            </p>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>
.activity-log-text small {
  font-size: 11px !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
